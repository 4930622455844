import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { EffectFade } from 'swiper/modules';
import 'swiper/css/effect-fade';
import useSwiperLogic from '../../components/useSwiperLogic';
import { useDarkMode } from '../../context/DarkModeContext';

const SebastienNoundry = ({ isCurrent }) => {
  const { isDarkMode } = useDarkMode();
  const sebastienNoundryRef = useRef(null);
  const swiperContainerRef = useRef(null);

  useSwiperLogic('sebastienNoundry', sebastienNoundryRef, swiperContainerRef, isCurrent);

  const slides = isDarkMode
    ? [
        {
          srcSet: '/assets/medias/10_noundry/noundry-poster-mobile-1.webp',
          media: '(max-width: 940px)',
          src: '/assets/medias/10_noundry/noundry-poster-1.webp',
          alt: "Sebastien's Noundry",
        },
      ]
    : [
        {
          srcSet: '/assets/medias/10_noundry/noundry-poster-mobile-2.webp',
          media: '(max-width: 940px)',
          src: '/assets/medias/10_noundry/noundry-poster-2.webp',
          alt: "Sebastien's Noundry",
        }
      ];

  const swiperClass = slides.length > 1 ? 'swiper-pointer' : '';

  return (
    <div className="work border noundry" id="sebastienNoundry" ref={swiperContainerRef}>
      <div className={`c ${swiperClass}`}>
        <Swiper
          ref={sebastienNoundryRef}
          loop={true}
          modules={[EffectFade]}
          effect="fade"
          spaceBetween={0}
          slidesPerView={1}
          onSwiper={(swiper) => (sebastienNoundryRef.current = swiper)}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <picture>
                <source srcSet={slide.srcSet} media={slide.media} />
                <img src={slide.src} alt={slide.alt} loading="lazy" />
                <div className="loader">
                  <div className="loader--animation"></div>
                </div>
              </picture>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default SebastienNoundry;
