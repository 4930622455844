import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { EffectFade } from 'swiper/modules';
import 'swiper/css/effect-fade';
import useSwiperLogic from '../../components/useSwiperLogic';

const Ode = ({ isCurrent }) => {
  const odeRef = useRef(null);
  const swiperContainerRef = useRef(null);

  // Apply Swiper logic
  useSwiperLogic('ode', odeRef, swiperContainerRef, isCurrent);

  const slides = [
    {
      src: '/assets/medias/ode/ode-1.webp',
      alt: 'Ode',
    },
    {
      src: '/assets/medias/ode/ode-2.webp',
      alt: 'Ode',
    },
    {
      src: '/assets/medias/ode/ode-3.webp',
      alt: 'Ode',
    },
    {
      src: '/assets/medias/ode/ode-4.webp',
      alt: 'Ode',
    },
    {
      src: '/assets/medias/ode/ode-5.webp',
      alt: 'Ode',
    },
  ];

  const swiperClass = slides.length > 1 ? 'swiper-pointer' : '';

  return (
    <div className="work" id="ode" ref={swiperContainerRef}>
      <div className={`c ${swiperClass}`}>
        <Swiper
          ref={odeRef}
          loop={true}
          modules={[EffectFade]}
          effect="fade"
          spaceBetween={0}
          slidesPerView={1}
          onSwiper={(swiper) => (odeRef.current = swiper)}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <img src={slide.src} alt={slide.alt} loading="lazy" />
              <div className="loader">
                <div className="loader--animation"></div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Ode;