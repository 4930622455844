export const instagram = `
  <svg width="360" height="420" viewBox="0 0 360 420" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M90 30L60 30V60H30V90H0V120V150V180V210V240V270V300V330H30V360H60V390H90H120H150H180H210H240H270H300V360H330V330H360V300V270V240V210V180V150V120V90H330V60H300V30H270L240 30L210 30L180 30L150 30H120H90ZM300 60V90H330V120V150V180V210V240V270V300V330H300V360H270H240H210H180H150H120H90H60V330H30V300V270V240V210V180V150V120V90H60V60H90H120H150H180H210H240H270H300ZM120 180V210V240V270H90V240V210V180V150H120V180ZM240 150H210H180H150H120V120H150H180H210H240V150ZM240 270V240V210V180V150H270V180V210V240V270H240ZM240 270H210H180H150H120V300H150H180H210H240V270Z" class="svg-fill"/>
  </svg>
`;

export const deca = `
  <svg width="360" height="420" viewBox="0 0 360 420" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M330 30H360V60H330V30ZM300 90V60H330V90H300ZM270 120V90H300V120H270ZM240 150V120H270V150H240ZM210 180V150H240V180H210ZM180 210V180H210V210H180ZM150 240H180V210H150V240ZM120 270V240H150V270H120ZM90 300H120V270H90V300ZM60 330V300H90V330H60ZM30 360V330H60V360H30ZM30 360H1.90735e-06L0 390H30V360ZM210 300H180V330H150V360H120V390H150V360H180V330H210V300ZM240 270V300H210V270H240ZM270 240H240V270H270V240ZM300 210H270V240H300V210ZM330 180H300V210H330V180ZM330 180H360V150H330V180ZM330 300H300V330H270V360H240V390H270V360H300V330H330V300ZM330 300H360V270H330V300ZM90 60H60V90H30V120H1.90735e-06V150H30V120H60V90H90V60ZM90 60H120V30H90V60ZM90 180H60V210H30V240H1.90735e-06L0 270H30V240H60V210H90V180ZM120 150V180H90V150H120ZM150 120V150H120V120H150ZM180 90V120H150V90H180ZM180 90V60H210V90H180ZM210 60V30H240V60H210Z" class="svg-fill"/>
  </svg>
`;

export const warpcast = `
  <svg width="420" height="420" viewBox="0 0 420 420" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M90 30H120H150H180H210H240H270H300H330V60V90H360H390H420V120H390V150V180V210V240V270V300V330V360V390H360H330H300V360V330V300V270V240V210V180H270V150H240V120H210H180V150H150V180H120V210V240V270V300V330V360V390H90H60H30V360V330V300V270V240V210V180V150V120H0V90H30H60H90V60V30Z" class="svg-fill"/>
  </svg>
`;

export const x = `
  <svg width="360" height="480" viewBox="0 0 360 480" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M214.284 212.453L348.324 60H316.572L200.136 192.346L107.208 60H0L140.556 260.149L0 420H31.752L154.632 280.208L252.792 420H360M43.212 83.4285H91.992L316.548 397.723H267.756" class="svg-fill"/>
  </svg>
`;

export const anchor = `
  <svg width="90" height="150" viewBox="0 0 90 150" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="50" y="70" width="10" height="10" class="svg-fill"/>
    <rect x="40" y="60" width="10" height="10" class="svg-fill"/>
    <rect x="40" y="80" width="10" height="10" class="svg-fill"/>
    <rect x="30" y="50" width="10" height="10" class="svg-fill"/>
    <rect x="30" y="90" width="10" height="10" class="svg-fill"/>
  </svg>
`;

export const slash = `
  <svg width="90" height="150" viewBox="0 0 90 150" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M65 55H55V65H65V55Z" class="svg-fill" />
    <path d="M55 65H45V75H55V65Z" class="svg-fill" />
    <path d="M45 75H35V85H45V75Z" class="svg-fill" />
    <path d="M35 85H25V95H35V85Z" class="svg-fill" />
  </svg>
`;

export const sun = `
  <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M50 10H40V20H50V10ZM20 20H30V30H20V20ZM60 30H50H40H30V40V50V60H20V70H30V60H40H50H60V70H70V60H60V50V40V30ZM60 30V20H70V30H60ZM20 40H10V50H20V40ZM80 40V50H70V40H80ZM50 80V70H40V80H50Z"
      class="svg-fill"
    />
  </svg>
`;

export const cc0 = `
  <svg width="90" height="150" viewBox="0 0 90 150" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="svg-fill" d="M10 40H20H30H40H50H60H70H80V50H70H60H50H40H30H20H10V40ZM10 100H0V90V80V70V60V50H10V60V70V80V90V100ZM80 100V110H70H60H50H40H30H20H10V100H20H30H40H50H60H70H80ZM80 100H90V90V80V70V60V50H80V60V70V80V90V100ZM20 60H30H40V70H30V80H40V90H30H20V80V70V60ZM60 60H50V70V80V90H60H70V80H60V70H70V60H60Z"/>
  </svg>
`;
