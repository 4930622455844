import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { EffectFade } from 'swiper/modules';
import 'swiper/css/effect-fade';
import useSwiperLogic from '../../components/useSwiperLogic';

const Heroglyphs = ({ isCurrent }) => {
  const heroRef = useRef(null);
  const swiperContainerRef = useRef(null);

  // Apply the reusable Swiper logic hook
  useSwiperLogic('heroglyphs', heroRef, swiperContainerRef, isCurrent);

  const slides = [
    {
      src: '/assets/medias/09_heroglyphs/heroglyphs-02.webp',
      alt: 'Heroglyphs',
    },
    {
      src: '/assets/medias/09_heroglyphs/heroglyphs-00.webp',
      alt: 'Heroglyphs',
    },
    {
      src: '/assets/medias/09_heroglyphs/heroglyphs-01.webp',
      alt: 'Heroglyphs',
    },
    {
      src: '/assets/medias/09_heroglyphs/heroglyphs-03.webp',
      alt: 'Heroglyphs',
    },
    {
      src: '/assets/medias/09_heroglyphs/heroglyphs-04.webp',
      alt: 'Heroglyphs',
    },
    {
      src: '/assets/medias/09_heroglyphs/heroglyphs-05.webp',
      alt: 'Heroglyphs',
    },
    {
      src: '/assets/medias/09_heroglyphs/heroglyphs-06.webp',
      alt: 'Heroglyphs',
    },
    {
      src: '/assets/medias/09_heroglyphs/heroglyphs-07.webp',
      alt: 'Heroglyphs',
    },
  ];

  const swiperClass = slides.length > 1 ? 'swiper-pointer' : '';

  return (
    <div className="work" id="heroglyphs" ref={swiperContainerRef}>
      <div className={`c ${swiperClass}`}>
        <Swiper
          ref={heroRef}
          loop={true}
          modules={[EffectFade]}
          effect="fade"
          spaceBetween={0}
          slidesPerView={1}
          onSwiper={(swiper) => (heroRef.current = swiper)}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <img
                src={slide.src}
                alt={slide.alt}
                loading="lazy"
              />
              <div className="loader">
                <div className="loader--animation"></div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Heroglyphs;
