import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { EffectFade } from 'swiper/modules';
import 'swiper/css/effect-fade';
import useSwiperLogic from '../../components/useSwiperLogic'; // Import the reusable swiper logic

const Graffiti = ({ isCurrent }) => {
  const graffitiRef = useRef(null);
  const swiperContainerRef = useRef(null);

  // Reuse the swiper logic with the Graffiti-specific parameters
  const { isDarkMode } = useSwiperLogic('graffiti', graffitiRef, swiperContainerRef, isCurrent);

  return (
    <div className="work graffiti" id="graffiti" ref={swiperContainerRef}>
      <div className="c">
        <Swiper
          ref={graffitiRef}
          loop={true}
          modules={[EffectFade]}
          effect="fade"
          spaceBetween={0}
          slidesPerView={1}
          onSwiper={(swiper) => (graffitiRef.current = swiper)}
        >
          {isDarkMode ? (
            // Dark mode: display the last slide
            <SwiperSlide>
              <img src="/assets/medias/00_graffiti/6.webp" alt="2035 Crew" />
              <div className="loader">
                <div className="loader--animation"></div>
              </div>
            </SwiperSlide>
          ) : (
            // Light mode: display the first two slides
            <>
              <SwiperSlide>
                <img src="/assets/medias/00_graffiti/7.webp" alt="2035 Crew" />
                <div className="loader">
                  <div className="loader--animation"></div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <img src="/assets/medias/00_graffiti/8.webp" alt="2035 Crew" />
                <div className="loader">
                  <div className="loader--animation"></div>
                </div>
              </SwiperSlide>
            </>
          )}
        </Swiper>
      </div>
    </div>
  );
};

export default Graffiti;
